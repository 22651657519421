import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import { get } from 'lodash';
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

import ObjectStorage from '../modules/ObjectStorage';
import AuthService from '../services/API/AuthService';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  admin: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, admin } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      admin,
      user: admin
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    admin: null,
  }),
  REGISTER: (state, action) => {
    const { admin } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      admin,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const { admin } = localStorage.getItem('AdminData');

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              admin,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              admin: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            admin: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (props) => {
    const { email, password, status } = props;
    const response = await AuthService.postAdminSignInData({
      email,
      password,
      status,
    });

    const { accessToken, user } = response.data;

    ObjectStorage.setItem('accessToken', { accessToken });
    ObjectStorage.setItem('AdminData', user);

    dispatch({
      type: 'LOGIN',
      isAuthenticated: true,
      payload: { user },
    });
  };

  const register = async (props) => {
    const {
      name,
      contactNumber,
      password,
      email,
    } = props;

    const response = await AuthService.postAdminSignUpData({
      name,
      contactNumber,
      roles:["admin"],
      password,
      email,
    });

    return get(response, 'data.data');
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
