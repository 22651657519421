import APIService from '../APIService';

const API = process.env.REACT_APP_ROY_EXCHANGE_API;

const AuthService = {
  postAdminSignInData(data) {
    return new Promise((resolve, reject) => {
      APIService.fetch(
        `${API}/auth/signin`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  postAdminSignUpData(data) {
    return new Promise((resolve, reject) => {
      APIService.fetch(
        `${API}/auth/signup`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
};

export default AuthService;
