import { Events } from '@poriyaalar/js-event';
import BridgeConstants from '../../constants/BridgeConstants';

const EventsService = {
  boot(cb) {
    window.customEventEmitter = new Events();
    window.onReceiveMessage = (nativeData) => {
      window.customEventEmitter.emit(BridgeConstants.NATIVE_DATA, nativeData);
    };
    cb();
  },
};

export default EventsService;
