// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ROOT_AUTH: 'auth',
  auth:'auth',
  SIGN_IN: 'sign_in',
  SIGN_UP: 'sign_up',
  APP: 'app',
  DASHBOARD: 'dashboard',
  dashboard:'dashboard',
  BOOKINGS: 'bookings',
  bookings:'bookings',
  CRYPTO_BOOKINGS: 'crypto_bookings',
  crypto_bookings:'crypto bookings',
  AGENT_SETTLEMENT: 'agent_settlement',
  agent_settlement:'agent amount settlement',
  USERS_DETAILS: 'users_details',
  users_details: 'users details',
  COMPLAINTS: 'complaints',
  complaints: 'complaints',
  CONVERSION_RATES: 'conversion_rates',
  conversion_rates: 'fiat conversions',
  KYC: 'KYC',
  CRYPTO_KYC: 'crypto_KYC',
  crypto_KYC:'crypto KYC',
  DROP_LOCATIONS: 'drop_locations',
  drop_locations:'drop locations',
  E_MONEY_CONVERSION_RATES: 'emoney_conversion_rates',
  emoney_conversion_rates:'e-money conversions',
  CRYPTO_CONVERSION_RATES: 'crypto_conversion_rates',
  crypto_conversion_rates:'crypto conversion',
  BANK_ACCOUNTS: 'bank_accounts',
  bank_accounts: 'bank accounts',
  WALLET_ADDRESS: 'wallet_address',
  wallet_address:'crypto wallet addresses',
  TRANSACTION_CHARGES: 'transaction_charges',
  CRYPTO_TRANSACTION_CHARGES: 'crypto_transaction_charges',
  ASSIGNEE_BOOKINGS: 'assignee_bookings',
  CAPTAINS: 'captains',
  captains:'captains',
  AGENTS: 'agents',
  agents: 'agents',
  AGENT_BOOKINGS: 'agent_bookings',
  AGENT_WALLET: 'agent_booking_wallet',
  NOTIFICATIONS: 'notification',
  REPORTS: 'reports',
  USERS: 'users',
  users:'users',
  TRANSACTIONS: 'transactions',
  transactions:'transactions',
  CRYPTO_USERS: 'crypto_users',
  crypto_users:'crypto users',
  CRYPTO_TRANSACTIONS: 'crypto_transactions',
  crypto_transactions:'crypto transactions',
  SETTINGS: 'settings',
  settings: 'settings',
  SETTLEMENT_AMOUNT: 'settlement_amount',
  TRANSACTION_HISTORY: 'transaction-history',
};
