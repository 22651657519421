import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import RouteConstants from "../constants/RouteConstants";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <Navigate to={`${RouteConstants.ROOT_AUTH}/${RouteConstants.SIGN_IN}`} />
  );
}
