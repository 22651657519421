import { format, getTime, formatDistanceToNow } from 'date-fns';
import dayjs from 'dayjs';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm p');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy p');
}

export function FORMAT_YEAR_MONTH_DATE(date) {
  return dayjs(date).format('YYYY-MM-DD');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function bookingDateFormat(date) {
  return dayjs(date).format('DD-MM-YYYY');
}

