import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import get from 'lodash/get';
import includes from 'lodash/includes';

import useAuth from '../hooks/useAuth';
import RouteConstants from '../constants/RouteConstants';
import StatusConstants from '../constants/StatusConstants';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  const isCaptain = includes(get(user, 'roles'), StatusConstants.CAPTAIN);
  const isAgent = includes(get(user, 'roles'), StatusConstants.AGENT);

  if (isAuthenticated) {
    if (isCaptain) {
      return (
        <Navigate
          to={`/${RouteConstants.APP}/${RouteConstants.ASSIGNEE_BOOKINGS}`}
        />
      );
    } else if (isAgent) {
      return (
        <Navigate
          to={`/${RouteConstants.APP}/${RouteConstants.AGENT_BOOKINGS}`}
        />
      );
    } else {
      return (
        <Navigate to={`/${RouteConstants.APP}/${RouteConstants.DASHBOARD}`} />
      );
    }
  }

  return <>{children}</>;
}
