import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import RouteConstants from '../constants/RouteConstants';
import { PATH_DASHBOARD } from './paths';
import { Settings } from '@mui/icons-material';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={
        <LoadingScreen isDashboard={pathname.includes('/dashboard')} />
      }>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          element: (
            <Navigate
              to={`/${RouteConstants.ROOT_AUTH}/${RouteConstants.SIGN_IN}`}
              replace
            />
          ),
          index: true,
        },
      ],
    },

    {
      path: RouteConstants.ROOT_AUTH,
      children: [
        {
          path: RouteConstants.SIGN_IN,
          element: (
            <GuestGuard>
              <SignIn />
            </GuestGuard>
          ),
        },
        {
          path: RouteConstants.SIGN_UP,
          element: (
            <GuestGuard>
              <SignUp />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password/:token', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: RouteConstants.APP,
          children: [
            {
              path: RouteConstants.DASHBOARD,
              element: <Dashboard />,
            },
            {
              path: RouteConstants.BOOKINGS,
              element: <Bookings />,
            },
            {
              path: RouteConstants.CRYPTO_BOOKINGS,
              element: <CryptoBookings />,
            },
            {
              path: RouteConstants.AGENT_SETTLEMENT,
              element: <AgentSettlement />,
            },
            {
              path: RouteConstants.USERS_DETAILS,
              element: <UsersDetails />,
            },
            {
              path: RouteConstants.COMPLAINTS,
              element: <Complaints />,
            },
            {
              path: RouteConstants.CONVERSION_RATES,
              element: <ConversionRates />,
            },
            {
              path: RouteConstants.KYC,
              element: <KYC />,
            },
            {
              path: `${RouteConstants.KYC}/:id`,
              element: <KYCFiles />,
            },
            {
              path: RouteConstants.CRYPTO_KYC,
              element: <CryptoKYC />,
            },
            {
              path: `${RouteConstants.CRYPTO_KYC}/:id`,
              element: <CryptoKYCFiles />,
            },
            {
              path: RouteConstants.DROP_LOCATIONS,
              element: <DropLocations />,
            },
            {
              path: RouteConstants.BANK_ACCOUNTS,
              element: <BankAccounts />,
            },
            {
              path: RouteConstants.TRANSACTION_CHARGES,
              element: <TransactionCharges />,
            },
            {
              path: RouteConstants.CRYPTO_TRANSACTION_CHARGES,
              element: <CryptoTransactionCharges />,
            },
            {
              path: RouteConstants.WALLET_ADDRESS,
              element: <WalletAddress />,
            },
            {
              path: RouteConstants.E_MONEY_CONVERSION_RATES,
              element: <EMoneyConversionRates />,
            },
            {
              path: RouteConstants.CRYPTO_CONVERSION_RATES,
              element: <CryptoConversionRates />,
            },
            {
              path: RouteConstants.ASSIGNEE_BOOKINGS,
              element: <AssigneeBookings />,
            },
            {
              path: RouteConstants.CAPTAINS,
              element: <Captains />,
            },
            {
              path: RouteConstants.AGENTS,
              element: <Agents />,
            },
            {
              path: RouteConstants.AGENT_BOOKINGS,
              element: <AgentsBookings />,
            },
            {
              path: RouteConstants.AGENT_WALLET,
              element: <AgentsWallet />,
            },
            {
              path: RouteConstants.SETTLEMENT_AMOUNT,
              element: <SettlementScreen />,
            },
            {
              path: RouteConstants.TRANSACTION_HISTORY,
              element: <TransactionHistory />,
            },
            {
              path: RouteConstants.NOTIFICATIONS,
              element: <Notification />,
            },
            {
              path: RouteConstants.REPORTS,
              children: [
                {
                  element: <Navigate to={PATH_DASHBOARD.report.root} replace />,
                  index: true,
                },
                { path: RouteConstants.USERS, element: <Users /> },
                {
                  path: RouteConstants.TRANSACTIONS,
                  element: <Transactions />,
                },
                {
                  path: RouteConstants.CAPTAINS,
                  element: <CaptainReports />,
                },
                {
                  path: RouteConstants.AGENTS,
                  element: <AgentReports />,
                },
                {
                  path: RouteConstants.CRYPTO_USERS,
                  element: <CryptoUsers />,
                },
                {
                  path: RouteConstants.CRYPTO_TRANSACTIONS,
                  element: <CryptoTransactions />,
                },
              ],
            },
            {
              path: RouteConstants.SETTINGS,
              element: <AdminSettings />,
            },
          ],
        },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const SignIn = Loadable(lazy(() => import('../pages/auth/SignIn')));
const SignUp = Loadable(lazy(() => import('../pages/auth/SignUp')));
const ResetPassword = Loadable(
  lazy(() => import('../pages/auth/ResetPassword'))
);
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

const Dashboard = Loadable(lazy(() => import('../pages/app/AdminDashboard')));
const Bookings = Loadable(lazy(() => import('../pages/app/Bookings')));
const CryptoBookings = Loadable(
  lazy(() => import('../pages/app/CryptoBookings'))
);
const AgentSettlement = Loadable(
  lazy(() => import('../pages/app/AgentSettlement'))
);
const UsersDetails = Loadable(lazy(() => import('../pages/app/Users')));
const Complaints = Loadable(lazy(() => import('../pages/app/Complaints')));
const ConversionRates = Loadable(
  lazy(() => import('../pages/app/ConversionRates'))
);
const KYC = Loadable(lazy(() => import('../pages/app/KYC')));
const KYCFiles = Loadable(lazy(() => import('../pages/app/KYCFiles')));
const CryptoKYC = Loadable(lazy(() => import('../pages/app/CryptoKYC')));
const CryptoKYCFiles = Loadable(
  lazy(() => import('../pages/app/CryptoKYCFiles'))
);
const DropLocations = Loadable(
  lazy(() => import('../pages/app/DropLocations'))
);
const EMoneyConversionRates = Loadable(
  lazy(() => import('../pages/app/EMoneyConversionRates'))
);
const CryptoConversionRates = Loadable(
  lazy(() => import('../pages/app/CryptoConversionRates'))
);
const BankAccounts = Loadable(lazy(() => import('../pages/app/BankAccounts')));
const WalletAddress = Loadable(
  lazy(() => import('../pages/app/WalletAddress'))
);
const TransactionCharges = Loadable(
  lazy(() => import('../pages/app/TransactionCharges'))
);
const TransactionHistory = Loadable(
  lazy(() => import('../pages/app/TransactionHistory'))
);
const CryptoTransactionCharges = Loadable(
  lazy(() => import('../pages/app/CryptoTransactionCharges'))
);
const SettlementScreen = Loadable(
  lazy(() => import('../pages/app/SettlementScreen'))
);
const AssigneeBookings = Loadable(
  lazy(() => import('../pages/app/AssigneeBookings'))
);
const Captains = Loadable(lazy(() => import('../pages/app/Captains')));
const Agents = Loadable(lazy(() => import('../pages/app/Agents')));
const AgentsBookings = Loadable(
  lazy(() => import('../pages/app/AgentBookings'))
);
const AgentsWallet = Loadable(lazy(() => import('../pages/app/AgentsWallet')));
const Notification = Loadable(lazy(() => import('../pages/app/Notification')));
const Users = Loadable(lazy(() => import('../pages/app/Reports/Users')));
const Transactions = Loadable(
  lazy(() => import('../pages/app/Reports/Transactions'))
);
const CaptainReports = Loadable(
  lazy(() => import('../pages/app/Reports/Captains'))
);
const AgentReports = Loadable(
  lazy(() => import('../pages/app/Reports/Agents'))
);
const CryptoUsers = Loadable(
  lazy(() => import('../pages/app/Reports/CryptoUsers'))
);
const CryptoTransactions = Loadable(
  lazy(() => import('../pages/app/Reports/CryptoTransactions'))
);
const AdminSettings = Loadable(lazy(() => import('../pages/app/Settings')));

const Page404 = Loadable(lazy(() => import('../pages/Page404')));
