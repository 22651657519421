import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
import get from 'lodash/get';
import includes from 'lodash/includes';

import useLocales from '../../../hooks/useLocales';
import { NavListRoot } from './NavList';
import StatusConstants from '../../../constants/StatusConstants';
import ObjectStorage from '../../../modules/ObjectStorage';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}) {
  const { translate } = useLocales();
  const user = ObjectStorage.getItem('AdminData');

  const isCaptain = includes(get(user, 'roles'), StatusConstants.CAPTAIN);
  const isAdmin = includes(get(user, 'roles'), StatusConstants.ADMIN);
  const isAgent = includes(get(user, 'roles'), StatusConstants.AGENT);

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          {/* <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {translate(group.subheader)}
          </ListSubheaderStyle> */}

          {isCaptain &&
            group.captain.map((list) => (
              <NavListRoot
                key={list.title + list.path}
                list={list}
                isCollapse={isCollapse}
              />
            ))}

          {isAgent &&
            group.agent.map((list) => (
              <NavListRoot
                key={list.title + list.path}
                list={list}
                isCollapse={isCollapse}
              />
            ))}

          {isAdmin &&
            group.admin.map((list) => (
              <NavListRoot
                key={list.title + list.path}
                list={list}
                isCollapse={isCollapse}
              />
            ))}
        </List>
      ))}
    </Box>
  );
}
