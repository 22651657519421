import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import BusinessIcon from '@mui/icons-material/Business';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import GroupsIcon from '@mui/icons-material/Groups';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import HistoryIcon from '@mui/icons-material/History';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import WalletIcon from '@mui/icons-material/Wallet';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------;

const navConfig = [
  {
    admin: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.dashboard,
        icon: <DashboardIcon />,
      },
      {
        title: 'Bookings',
        path: PATH_DASHBOARD.bookings,
        icon: <CollectionsBookmarkIcon />,
      },
      {
        title: 'Crypto Bookings',
        path: PATH_DASHBOARD.crypto_bookings,
        icon: <CollectionsBookmarkIcon />,
      },
      {
        title: 'Agent Settlement',
        path: PATH_DASHBOARD.agent_settlement,
        icon: <CollectionsBookmarkIcon />,
      },
      {
        title: 'Users',
        path: PATH_DASHBOARD.users_details,
        icon: <AccountBoxIcon />,
      },
      {
        title: 'Complaints',
        path: PATH_DASHBOARD.complaints,
        icon: <ContactSupportIcon />,
      },
      {
        title: 'Fiat Conversions',
        path: PATH_DASHBOARD.conversion_rates,
        icon: <PriceChangeIcon />,
      },
      {
        title: 'KYC',
        path: PATH_DASHBOARD.kyc,
        icon: <HowToRegIcon />,
      },
      {
        title: 'Crypto KYC',
        path: PATH_DASHBOARD.crypto_kyc,
        icon: <HowToRegIcon />,
      },
      {
        title: 'Drop locations',
        path: PATH_DASHBOARD.drop_locations,
        icon: <BusinessIcon />,
      },
      {
        title: 'Bank accounts',
        path: PATH_DASHBOARD.bank_accounts,
        icon: <AccountBalanceIcon />,
      },
      {
        title: 'Crypto Wallet Addresses',
        path: PATH_DASHBOARD.wallet_address,
        icon: <AccountBalanceWalletIcon />,
      },
      // {
      //   title: 'Transaction Charges',
      //   path: PATH_DASHBOARD.transaction_charges,
      //   icon: <ReceiptLongIcon />,
      // },
      // {
      //   title: 'Crypto Transaction Charges',
      //   path: PATH_DASHBOARD.crypto_transaction_charges,
      //   icon: <ReceiptLongIcon />,
      // },
      {
        title: 'Crypto conversions',
        path: PATH_DASHBOARD.crypto_conversion_rates,
        icon: <CurrencyBitcoinIcon />,
      },
      {
        title: 'E-Money conversions',
        path: PATH_DASHBOARD.emoney_conversion_rates,
        icon: <MonetizationOnIcon />,
      },
      {
        title: 'Captains',
        path: PATH_DASHBOARD.captains,
        icon: <TwoWheelerIcon />,
      },
      {
        title: 'Agents',
        path: PATH_DASHBOARD.agents,
        icon: <GroupsIcon />,
      },
      // {
      //   title: 'Notification',
      //   path: PATH_DASHBOARD.notification,
      //   icon: <NotificationsActiveIcon />,
      // },
      {
        title: 'Reports',
        path: PATH_DASHBOARD.report.root,
        icon: <TextSnippetIcon />,
        children: [
          { title: 'Users', path: PATH_DASHBOARD.report.users },
          { title: 'Crypto users', path: PATH_DASHBOARD.report.crypto_users },
          { title: 'Transactions', path: PATH_DASHBOARD.report.transactions },
          {
            title: 'Crypto transactions',
            path: PATH_DASHBOARD.report.crypto_transactions,
          },
          { title: 'Captains', path: PATH_DASHBOARD.report.captains },
          { title: 'Agents', path: PATH_DASHBOARD.report.agents },
        ],
      },
      {
        title: 'Settings',
        path: PATH_DASHBOARD.settings,
        icon: <SettingsSuggestIcon />,
      },
    ],
    captain: [
      {
        title: 'Bookings',
        path: PATH_DASHBOARD.assignee_bookings,
        icon: <ContactEmergencyIcon />,
      },
    ],
    agent: [
      {
        title: 'Bookings',
        path: PATH_DASHBOARD.agents_bookings,
        icon: <ContactEmergencyIcon />,
      },
      {
        title: 'Wallet',
        path: PATH_DASHBOARD.agent_wallet,
        icon: <WalletIcon />,
      },
      {
        title: 'Request Payment',
        path: PATH_DASHBOARD.settlement_amount,
        icon: <PointOfSaleIcon />,
      },
      {
        title: 'Transaction History',
        path: PATH_DASHBOARD.transaction_history,
        icon: <HistoryIcon />,
      },
    ],
  },
];

export default navConfig;
