export default {
  ADDRESS: 'address',
  ACCOUNT: 'account',
  CAPTAIN: 'ROLE_CAPTAIN',
  AGENT: 'ROLE_AGENT',
  ADMIN: 'ROLE_ADMIN',
  CAPTAIN_STATUS: 'captainapproved',
  ADMIN_STATUS: 'adminapproved',
  AGENT_STATUS: 'agentapproved',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING: "pending",
  PICKED_UP: 'pickedup',
  DROPPED: 'dropped',
  SIGNUP_CAPTAIN: 'captain',
  SIGNUP_AGENT: 'agent',
  INR: 'INR',
  AED: 'AED',
  GBP: "GBP",
  USDT: 'USDT',
  SEND: 'send',
  WITHDRAW: 'withdraw',
  COMPLETED: 'completed',
  PASSPORT_PHOTO: 'passport photo',
  AADHAR_FRONT: 'aadhar front',
  AADHAR_BACK: 'aadhar back',
  NOT_SUBMITTED: 'Not submitted',
  PICKUP_LOCATION: 'pickUpLocation',
  DROP_LOCATION: 'dropLocation',
  ACCOUNT_TRANSFER: 'accountTransfer',
  ROY_EXCHANGE: 'royExchange',
  HABIBI : 'habibi',
  RESOLVED : 'resolved'
};
