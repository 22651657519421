import RouteConstants from '../constants/RouteConstants';

// ----------------------------------------------------------------------

function path(root, sublink) {
  return `/${root}/${sublink}`;
}

const REPORTS = `${RouteConstants.APP}/${RouteConstants.REPORTS}`;

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: RouteConstants.ROOT_AUTH,
  sign_in: path(RouteConstants.ROOT_AUTH, RouteConstants.SIGN_IN),
  edit: path(RouteConstants.ROOT_AUTH, RouteConstants.EDIT),
  sign_up: path(RouteConstants.ROOT_AUTH, RouteConstants.SIGN_UP),
  loginUnprotected: path(RouteConstants.ROOT_AUTH, 'login-unprotected'),
  registerUnprotected: path(RouteConstants.ROOT_AUTH, 'register-unprotected'),
  verify: path(RouteConstants.ROOT_AUTH, 'verify'),
  resetPassword: path(RouteConstants.ROOT_AUTH, 'reset-password'),
  newPassword: path(RouteConstants.ROOT_AUTH, 'new-password'),
};

export const PATH_DASHBOARD = {
  dashboard: path(RouteConstants.APP, RouteConstants.DASHBOARD),
  bookings: path(RouteConstants.APP, RouteConstants.BOOKINGS),
  crypto_bookings: path(RouteConstants.APP, RouteConstants.CRYPTO_BOOKINGS),
  agent_settlement: path(RouteConstants.APP, RouteConstants.AGENT_SETTLEMENT),
  users_details: path(RouteConstants.APP, RouteConstants.USERS_DETAILS),
  complaints: path(RouteConstants.APP, RouteConstants.COMPLAINTS),
  conversion_rates: path(RouteConstants.APP, RouteConstants.CONVERSION_RATES),
  kyc: path(RouteConstants.APP, RouteConstants.KYC),
  kyc_files: (id) => path(RouteConstants.APP, `${RouteConstants.KYC}/${id}`),
  crypto_kyc: path(RouteConstants.APP, RouteConstants.CRYPTO_KYC),
  crypto_kyc_files: (id) =>
    path(RouteConstants.APP, `${RouteConstants.CRYPTO_KYC}/${id}`),
  drop_locations: path(RouteConstants.APP, RouteConstants.DROP_LOCATIONS),
  emoney_conversion_rates: path(
    RouteConstants.APP,
    RouteConstants.E_MONEY_CONVERSION_RATES
  ),
  crypto_conversion_rates: path(
    RouteConstants.APP,
    RouteConstants.CRYPTO_CONVERSION_RATES
  ),
  bank_accounts: path(RouteConstants.APP, RouteConstants.BANK_ACCOUNTS),
  wallet_address: path(RouteConstants.APP, RouteConstants.WALLET_ADDRESS),
  transaction_charges: path(RouteConstants.APP, RouteConstants.TRANSACTION_CHARGES),
  crypto_transaction_charges: path(RouteConstants.APP, RouteConstants.CRYPTO_TRANSACTION_CHARGES),
  assignee_bookings: path(RouteConstants.APP, RouteConstants.ASSIGNEE_BOOKINGS),
  captains: path(RouteConstants.APP, RouteConstants.CAPTAINS),
  agents: path(RouteConstants.APP, RouteConstants.AGENTS),
  agents_bookings: path(RouteConstants.APP, RouteConstants.AGENT_BOOKINGS),
  agent_wallet: path(RouteConstants.APP, RouteConstants.AGENT_WALLET),
  settlement_amount: path(RouteConstants.APP, RouteConstants.SETTLEMENT_AMOUNT),
  transaction_history: path(RouteConstants.APP, RouteConstants.TRANSACTION_HISTORY),
  notification: path(RouteConstants.APP, RouteConstants.NOTIFICATIONS),
  reports: path(RouteConstants.APP, RouteConstants.REPORTS),
  report: {
    root: path(REPORTS, 'user'),
    users: path(REPORTS, RouteConstants.USERS),
    transactions: path(REPORTS, RouteConstants.TRANSACTIONS),
    captains: path(REPORTS, RouteConstants.CAPTAINS),
    agents: path(REPORTS, RouteConstants.AGENTS),
    crypto_users: path(REPORTS, RouteConstants.CRYPTO_USERS),
    crypto_transactions: path(REPORTS, RouteConstants.CRYPTO_TRANSACTIONS),
  },
  settings: path(RouteConstants.APP, RouteConstants.SETTINGS)
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
